import { NavLink } from "react-router-dom";
import { loadScript, publicUrlFor } from "../../../../globals/constants";
import { useEffect } from "react";

export default function SectionSlider1() {

    useEffect(()=>{
        loadScript('/assets/js/slider-home1.js', true);
        // loadScript('/assets/js/slider-home3.js', true);
        loadScript('/assets/js/bg-image-move.js', true);
    })

    return (
        <div className="twm-slider1-wrap">

            {/* Swiper */}
            <div className="swiper twm-slider">
                <div className="swiper-wrapper">
                    {/*Slide One */}
                    <div className="swiper-slide">
                        <div className="h-banner-wrap">
                            <div className="h-banner-left">
                                <div className="h-bnr-top">
                                    <h2>
                                        <span className="title-outline">Ready</span>
                                        To Move
                                    </h2>
                                    <p>
                                    Ahmed Shipping Ltd. is one of the oldest and most prominent names in this sector in Bangladesh. 
				</p>
                                    <NavLink to="/services/services1" className="h-bnr-btn">View Services</NavLink>
                                </div>

                            </div>
                            <div className="h-banner-right">
                                <div className="h-bnr-r-inner">
                                    <div className="h-bnr-media">
                                        <img src={publicUrlFor("/assets/images/main-slider/slider1/ship.png")} alt="#" className="slide-top" />
                                    </div>
                                    <div className="h-bnr-animation bg-cover bg-center bg-image-moving" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/main-slider/slider1/bg3.jpg')})` }}>

                                    </div>
                                </div>
                            </div>
                            <div className="cross-line-box left up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="cross-line-box right up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="circle-left-top zoon-in-out"></div>
                        </div>
                    </div>

{/*Slide Two */}
                    <div className="swiper-slide bg-cover" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/main-slider/slider1/bg-large.jpg')})` }}>
                            <div className="h-banner-wrap">
                                <div className="h-banner-left">
                                    <div className="h-bnr-top">
                                        <h2>
                                            <span className="title-outline">Around</span>
                                            The World
                                        </h2>
                                        <p> Our experience in all modes of transport coupled with our global service network enable BMS to provide custom solutions worldwide to meet your deadlines and budget.
                                        </p>
                                        <NavLink to="/services/services1" className="h-bnr-btn">View Services</NavLink>
                                    </div>
                                </div>
                                <div className="cross-line-box left up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                                <div className="cross-line-box right up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                                <div className="circle-left-top zoon-in-out" />
                            </div>
                        </div>
   {/*Slide Three */}
                    
					{/* We transport different types cargo through the inland waterway predominantly Petroleum, Oil & Lubricants, Edible Oil and Passengers.
				 */}
                    <div className="swiper-slide bg-cover bg-image-moving2" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/main-slider/slider3/bg-large.jpg')})` }}>
                            <div className="h-banner-wrap">
                                <div className="h-banner-left">
                                    <div className="h-bnr-top">
                                        <span className="title-small">The provide best</span>
                                        <h2>Shipping Products Worldwide</h2>
                                        <NavLink to="/services/services1" className="h3-bnr-btn site-button">Read More</NavLink>
                                    </div>
                                </div>
                                <div className="h-banner-right">
                                    <div className="h-bnr-r-inner">
                                        <div className="h-bnr-media slider-scale">
                                            <img src={publicUrlFor("/assets/images/main-slider/slider3/ship.png")} alt="#" className="slide-top" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ani-clowd1 clowd1-move"><img src={publicUrlFor("/assets/images/main-slider/slider3/clowd-1.png")} alt="#" /></div>
                            <div className="ani-clowd2 clowd2-move"><img src={publicUrlFor("/assets/images/main-slider/slider3/clowd-2.png")} alt="#" /></div>
                            <div className="ani-clowd3 clowd3-move"><img src={publicUrlFor("/assets/images/main-slider/slider3/clowd-3.png")} alt="#" /></div>
                        </div>

                  
                    

                </div>
                <div className="swiper-pagination"></div>
            </div>

        </div>
    )
}