import { global } from "../constants";

export const data = {
  whatwedo: {
    title: "What we do!",
    subtitle: "Specialist Logistics Services",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    image: global.icons + "img1.png",
    works: [
      {
        icon: global.icons + "pic1.png",
        serial: "01.",
        title: "Road Freight",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
      },
      {
        icon: global.icons + "pic2.png",
        serial: "02.",
        title: "Shipping Freight",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
      },
      {
        icon: global.icons + "pic3.png",
        serial: "03.",
        title: "Air Freight",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
      }
    ]
  },

  whychooseus: {
    background: global.back + "bg-1.jpg",
    title: "Why Choose Us",
    subtitle: "We Are Professional Logistics & cargo Agency",
    description: {
      short: "Sed ut perspiciatis unde omnis iste natus error volup tatem accusantium dolorem que laudantium, totam rem aperiam, eaque ipsa illo inventore.",
      long: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolorem que laudantium, totam rem aperiam, que ipsa quae ab illo inventore veritatis quasi architecto beatae itae dicta sunt explicabo. Nemo enim ipsam voluptate voluptas sitr atur aut odit aut fugit, sed quia consequuntur magni dolores is eos qui ratione voluptatem sequi nesciunt."
    },
    pic1: global.whyChoose + "pic1.jpg",
    pic2: global.whyChoose + "pic2.jpg",
    help: {
      icon: global.whyChoose + "qt-icon.png",
      number: "(2 900 234 4241)"
    }
  },

  booking: {
    background: global.booking + "bg-map.png",
    image: global.booking + "pic1.png",
    imageBack: "",
    title: "Request A Quote",
    subtitle: "Booking For Product Transformation",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png"
    }
  },

  estimation: {
    background: global.back + "bg-2.jpg",
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.",
    solutions: [
      {
        serial: "01",
        title: "Solutions and specialized expertise",
        subtitle: "Our aim is to optimize and improve your supply chain so that we can give you the best service"
      },
      {
        serial: "02",
        title: "Multiple warehouses",
        subtitle: "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind..."
      },
      {
        serial: "03",
        title: "Tracking made easy",
        subtitle: "A tracking number for the entire process. so that you can find the exact position. it will help you"
      }
    ]
  },

  testimonials: {
    title: "Respected",
    subtitle: "Clients & partners",
    clients: [
      global.logo + "/w1.png",
      global.logo + "/w2.png",
      global.logo + "/w3.png",
      global.logo + "/w4.png",
      global.logo + "/w5.png",
      global.logo + "/w6.png",
    ],
    partners: [
      {
        pic: global.testim + "pic1.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Peter Len",
        designation: "Web Designer"
      },
      {
        pic: global.testim + "pic2.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Giselle",
        designation: "Developer"
      },
      {
        pic: global.testim + "pic3.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Josephine",
        designation: "Web Designer"
      },
      {
        pic: global.testim + "pic4.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Penelope",
        designation: "Developer"
      }
    ]
  },

  services: {
    title: "About Us",
    subtitle: "Trusted For Our Services",
    description: "Today Highspeed is a highly respected name in the country taking part in international and domestic shipping, shipbuilding & heavy engineering, and construction of large infrastructure projects including international trading.",
    services: [
      {
        image: global.sgallery + "1.jpg",
        title: "Our Philosophy",
        serial: "",
        description: "By the grace of Almighty Allah, mankind has achieved the impossible, conquered the insurmountable, and accomplished the improbable only by effective mobilization of human resources. At Highspeed, we work together to harness the human resources for the human race."
      },
      {
        image: global.sgallery + "2.jpg",
        title: "ROur Mission",
        serial: "",
        description: "We take care of your dream sincerely, As we take pride in our name seriously. Our constant endeavor has been to provide our clients with impeccable service and we persistently strive for the best outcome for each of our clients and every one of our undertakings."
      },
      {
        image: global.sgallery + "3.jpg",
        title: "Our Vision",
        serial: "",
        description: "We envisage our business to grow and simultaneously eliminate our environmental footprint in order to strike a harmonious balance between our positive social impact and strife for sustainable living."
      }
    ]
  },
 
  services2: {
    title: "All services",
    subtitle: "Trusted For Our Services",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    services: [
        {
            icon: global.icons + "pic1.png",
            serial: "01",
            title: "Shipbuilding and Engineering",
            description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
        },
        {
            icon: global.icons + "pic2.png",
            serial: "02",
            title: "Watercraft Repair Services",
            description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
        },
        {
            icon: global.icons + "pic3.png",
            serial: "03",
            title: "International Shipping",
            description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
        },
        {
            icon: global.icons + "pic4.png",
            serial: "04",
            title: "Freight Forwarding Services",
            description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
        },
        {
            icon: global.icons + "pic5.png",
            serial: "05",
            title: "Construction",
            description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
        },
        {
            icon: global.icons + "pic6.png",
            serial: "06",
            title: "Petroleum, Oil & Lubricants (POL) Carrier",
            description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
        }
    ]
},

  approach: {
    title: "Company Approch",
    subtitle: "Reliable Logistic & Transport Solutions",
    yearsOfExperience: "35",
    specifications: [
      {
        icon: global.icons + "c-pic1.png",
        count: 195,
        title: "Happy Clients"
      },
      {
        icon: global.icons + "c-pic2.png",
        count: 250,
        title: "Years of Experience"
      },
      {
        icon: global.icons + "c-pic3.png",
        count: 350,
        title: "Vessels built under Class, MSO & ISO"
      },
      {
        icon: global.icons + "c-pic3.png",
        count: 3500,
        title: "Trips"
      }
    ]
  },

  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    blogs: [
      {
        image: global.blog1 + "bg1.jpg",
        day: "02",
        month: "Aug",
        title: "At the end of the day, going forward, a new normal that has evolved from.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      },
      {
        image: global.blog2 + "l-1.jpg",
        day: "08",
        month: "Aug",
        title: "How all this mistaken idea denouncing pleasure and praising pain was.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      },
      {
        image: global.blog1 + "bg3.jpg",
        day: "21",
        month: "Aug",
        title: "Going forward, a new normal that has evolved from generation.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      }
    ]
  },

  projects: {
    title: "Projects",
    subtitle: "Featured Projects",
    projects: [
      {
        image: global.projects + "1.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge"
      },
      {
        image: global.projects + "2.jpg",
        title: "Logistics, Analytics",
        subtitle: "Minimize Manufacturing",
        caption: "Nh-16 Highway Bridge"
      },
      {
        image: global.projects + "3.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge"
      }
    ]
  },

  team: {
    title: "Our Team",
    subtitle: "Our Team",
    projects: [
      {
        image: global.projects + "1.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge"
      },
      {
        image: global.projects + "2.jpg",
        title: "Logistics, Analytics",
        subtitle: "Minimize Manufacturing",
        caption: "Nh-16 Highway Bridge"
      },
      {
        image: global.projects + "3.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge"
      }
    ]
  }

}
